import styled from "styled-components"
import Typography from "@/components/styled/Typography"
import { PrimaryButton } from "@/components/styled/Buttons"
import { Row, SectionContainer } from "@/components/styled/Grid"
import Col from "@/components/styled/Grid/Col"
import Image from "next/image"
import * as PropTypes from "prop-types"
import Link from "next/link"

const Text = styled(Typography)`
  font-weight: 400;
`

export default function MagnetoRent({ title = "Alquiler Magnetoterapia" }) {
  return (
    <SectionContainer style={{ overflow: "visible" }}>
      <Typography variant="title2" className="mb-2" $weight="medium" center>
        {title}
      </Typography>
      <Row style={{ alignItems: "center" }}>
        <Col xs={12} md={5} style={{ textAlign: "center" }}>
          <Image
            alt="Physio WOW top"
            src="https://res.cloudinary.com/physio-wow/image/upload/w_400,q_auto,f_auto/static/alquiler/magnetofield"
            width={400}
            height={400}
            style={{ height: "auto" }}
          />
        </Col>
        <Col xs={12} md={7}>
          <Text className="mb-4">
            En Physio WOW tenemos equipos de magnetoterapia para alquilar en Sant Cugat, Barcelona y alrededores.
            Potencia tu bienestar y alivia el dolor con nuestra tecnología de vanguardia, sin tener que desplazarte.
          </Text>
          <Link href="/alquiler-magnetoterapia" passHref>
            <PrimaryButton size="md" className="mb-4">
              Alquilar Magnetoterapia
            </PrimaryButton>
          </Link>
        </Col>
      </Row>
    </SectionContainer>
  )
}

MagnetoRent.propTypes = {
  title: PropTypes.string
}
